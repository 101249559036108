import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DownloadIcon from '@mui/icons-material/Download';
import HomeIcon from '@mui/icons-material/Home';
import { useFetchInterceptor } from "../../hooks/useFetchInterceptor";
import { capitalizeFirst, generateReceiptNo } from "../../utils";
import { createPdfTemplate } from "../../utils/createPdfTemplate";
import { payment } from "../../mocks/constants";
import Loader from "../../containers/Loader";
import { setUser } from "../../redux/user/userSlice";

const PaymentSuccess = () => {
    // useParams => get url params (route params).
    const dispatch = useDispatch();
    const {orderId} = useParams(); // this orderId is not _id (objectId), it's generated razorpay orderId
    const navigate = useNavigate();
    const location = useLocation();
    const fetchApiInterceptors = useFetchInterceptor();
    const {user} = useSelector((state) => state.user);
    const {token} = useSelector((state) => state.auth);
    const [isGeneratingInvoice, setIsGeneratingInvoice] = useState(false);
    const [currentOrder, setCurrentOrder] = useState({
      orderDetails: [],
      status: "",
      totalAmount: 0,
      payments: [],
      orderDate: "",
    });

    // after successful order placed user's cart is being empty from backend, doing here from frontend as well:
    useEffect(() => {
      dispatch(setUser({...user, carts: location.state ? location.state?.carts : user.carts }))
    }, [location.state]);

    // get the order details (in case if user reloads the page after order success.)
    useEffect(() => {
      const getCurrentOrderDetails = async() => {
        
          const options = {
            method: 'GET',
            credentials: "include",
            headers: {
                Accept: "application/json",
            },
          }
          const result = await fetchApiInterceptors(`/api/user/${user.user_id}/order/${orderId}`, token, options);
          if(result.data){
            const address = await fetchApiInterceptors(`/api/address/${result.data.deliveryAddress}`, token, options);
            const payment = await fetchApiInterceptors(`/api/user/${user.user_id}/payment/${result.data.payments[0]}`, token, options);
            if(result.success){
              setCurrentOrder({...result.data, address: address.data, trxnId: payment.data.transactionId });
            }
          }
      }
      if(token && user.user_id) getCurrentOrderDetails();
    }, [user, token]);

     const generateInvoice = async () => {
      try{
        setIsGeneratingInvoice(true);
      const products = currentOrder.orderDetails.map(product => {
        return {
          name: product.workerType,
          description: product.workerSubType,
          amount: product.price,
          quantity: product.quantity,
        }
      })
        const invoiceData = {
          customerName: currentOrder.address.name, // user name or address name
          shopName: "", // 
          shopAddress: `${currentOrder.address.street} ${currentOrder.address.city} ${currentOrder.address.state} ${currentOrder.address.zip}`,
          invoiceId: `${generateReceiptNo(currentOrder.address.phone, user.user_id, user.googleId)}`,
          orderId: currentOrder.orderId,
          trxnId: currentOrder.trxnId,
          date: new Date(currentOrder.orderDate).toLocaleDateString(),
          products,
          mobileNo: currentOrder.address.phone,
          subTotal: currentOrder.subTotal,
          discount: currentOrder.discount,
          tax: currentOrder.tax.toFixed(2),
          demoFee: payment.demoFee,
          platformFee: payment.platformFee,
          total: currentOrder.totalAmount.toFixed(2),
        };
        const options = {
          method: "POST",
          credentials: 'include',
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify({ htmlContent: createPdfTemplate(invoiceData) }),
        }
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/generate-invoice`, options);
    
        if (response.ok) {
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "invoice.pdf";
          document.body.appendChild(a);
          a.click();
          a.remove(); //a.remove() => browser compatibility issue or we can use document.body.removeChild(a) (universally supported)
          window.URL.revokeObjectURL(url);
        } else {
          alert("Failed to generate invoice");
        }
      }catch(err){
        console.log(err);
      }finally{
        setIsGeneratingInvoice(false);
      }
      };

      // will remove this function later: (no need of preview invoice)
      const previewInvoice = () => {
        navigate("/preview-invoice");
      };

  return (
    <div className="container payment-container success-container mt-20">

      <div className="bg-white rounded-lg shadow-sm p-6 md:p-8">
          {/* Success Header */}
          <div className="text-center mb-8">
            <div className="inline-flex items-center justify-center w-16 h-16 bg-green-100 rounded-full mb-4">
              <CheckCircleIcon className="w-8 h-8 text-green-600" />
            </div>
            <h1 className="text-2xl font-bold text-gray-900 mb-2">Order Placed Successfully!</h1>
            <p className="text-gray-600">
              {`Thank you ${capitalizeFirst(user.name)} . Your order has been confirmed.`}
            </p>
          </div>

          {/* Order Details */}
          <div className="border rounded-lg p-6 mb-8 bg-gray-50">
            <div className="flex justify-between mb-4">
              <div>
                <p className="text-sm text-gray-600">Order Number</p>
                <p className="font-semibold">{orderId}</p>
              </div>
              <div className="text-right">
                <p className="text-sm text-gray-600">Order Date</p>
                <p className="font-semibold">{currentOrder.orderDate}</p>
              </div>
            </div>

            {/* Order Items */}
            <div className="space-y-3 mb-4">
              {currentOrder?.orderDetails?.map((item) => (
                <div key={item.worker} className="flex justify-between text-sm">
                  <span className="text-gray-600">
                    {`${item.workerType} (${item.workerSubType})`} × {item.quantity}
                  </span>
                </div>
              ))}
            </div>

            <div className="border-t pt-4">
              <div className="flex justify-between font-semibold">
                <span>Total Amount</span>
                <span>{(currentOrder.totalAmount.toFixed(2))} INR</span>
              </div>
            </div>
          </div>

          {/* Action Buttons */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <button onClick={generateInvoice} disabled={isGeneratingInvoice} className="flex items-center justify-center gap-2 px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors">
              {isGeneratingInvoice ? <Loader /> : <><DownloadIcon className="w-5 h-5" />Download Invoice</>}
            </button>
            {/* <button onClick={previewInvoice} className="flex items-center justify-center gap-2 px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors">
              <DownloadIcon className="w-5 h-5" />
              Preview Invoice
            </button> */}
            <button className="flex items-center justify-center gap-2 px-6 py-3 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition-colors"
            onClick={() => navigate('/')}>
              <HomeIcon className="w-5 h-5" />
              Back to Home
            </button>
          </div>
        </div>
    </div>
  );
};

export default PaymentSuccess;
