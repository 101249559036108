import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    orderDetails: {
        currency: 'INR',
        receiptNo: '',
        amount: 0,
        totalAmount: 0,
        discount: 0,
        tax: 0,
        selectedUserAddress: {}, // id
        workerDetails: [],
        user: '' // id
    },
    loading: false,
    selectedUserAddress: {},
}

export const checkOutSlice = createSlice({
    name:'checkout',
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setSelectedUserAddress: (state, action) => {
            state.selectedUserAddress = action.payload;
        },
        setOrderDetails: (state, action) => {
            state.orderDetails = action.payload;
        }
    }
})

export const {setLoading, setSelectedUserAddress, setOrderDetails} = checkOutSlice.actions;
export default checkOutSlice.reducer;
