export const createPdfTemplate = ({
  customerName,
  shopName,
  shopAddress,
  invoiceId,
  orderId,
  trxnId,
  date,
  products,
  mobileNo,
  subTotal,
  discount,
  tax,
  demoFee,
  platformFee,
  total,
}) => {
  const htmlContent = `
    <!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <title>Invoice</title>
    <style>
      body {
        font-family: Arial, sans-serif;
        background-color: #f3f4f6;
        margin: 0;
        padding: 0;
      }
      .container {
        max-width: 800px;
        margin: 20px auto;
        background-color: #ffffff;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        overflow: hidden;
      }
      .header {
        background-color: #c9740c;
        color: #ffffff;
        padding: 20px 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .header img {
        height: 44px;
      }
      .header h1 {
        margin: 0;
        font-size: 24px;
      }
      .header .invoice-details {
        text-align: right;
      }
      .header .invoice-details h2 {
        margin: 0;
        font-size: 28px;
      }
      .billing-section {
        display:flex;
        justify-content: space-between;
        align-items: center;
      }
      .section {
        padding: 20px 30px;
        border-bottom: 1px solid #e5e7eb;
      }
      .section:last-child {
        border-bottom: none;
      }
      .section h3 {
        margin-top: 0;
        font-size: 18px;
        color: #6b7280;
      }
      .section p {
        margin: 5px 0;
      }
      .table {
        width: 100%;
        border-collapse: collapse;
        margin-top: 20px;
      }
      .table th,
      .table td {
        border: 1px solid #e5e7eb;
        padding: 10px;
        text-align: left;
      }
      .table .text-font-600 {
        font-weight: 600;
      }
      .table .text-gray-600-sm {
        color: #4b5563;
        font-size: 0.875rem;
      }
      .table th {
        background-color: #f3f4f6;
      }
      .totals-section {
        margin-top: 1.5rem;
        display: flex;
        justify-content: flex-end;
      }

      .totals-summary {
        width: 16rem; /* Equivalent to w-64 in Tailwind */
      }

      .totals-item {
        display: flex;
        justify-content: space-between;
        padding-top: 0.5rem; /* Equivalent to py-2 in Tailwind */
        padding-bottom: 0.5rem;
      }

      .totals-item:last-child {
        border-top: 2px solid #d1d5db; /* Equivalent to border-gray-300 */
        font-weight: bold; /* Equivalent to font-bold */
      }

      .footer {
        padding: 20px 30px;
        background-color: #f9fafb;
      }
      .footer .refund-icon {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-bottom: 0px;
      }
      .footer .w-7 {
        width: 1.75rem;
        height: 1.75rem;
      }
      .footer .text-orange-400 {
        color: #fb923c; /* Tailwind's orange-400 */
      }
      .footer h3 {
        margin: 8px 0 10px 0;
        font-size: 16px;
        color: #6b7280;
        text-decoration: underline;
      }
      .footer ul {
        padding-left: 50px;
        margin: 0;
        list-style-type: disc;
      }
      .footer ul li {
        margin-bottom: 5px;
        color: #6b7280;
      }
    </style>
  </head>
  <body>
    <div class="container">
      <div class="header">
        <div class="brand">
          <h1>HomelyChef</h1>
        </div>
        <div class="invoice-details">
          <h2>INVOICE</h2>
          <p>Invoice No: ${invoiceId}</p>
          <p>Date: ${date}</p>
        </div>
      </div>

      <div class="section billing-section">
      <div class="billing-details">
        <h3>Bill To:</h3>
        <p><strong>${customerName}</strong></p>
        <p>${shopName}</p>
        <p>${shopAddress}</p>
        <p><span style="color: #c9740c">&#128222;</span> ${mobileNo}</p>
      </div>
      <div class="order-trxn-id">
        <p> <strong> Order Id: </strong> ${orderId} </p>
        <p> <strong> Transaction Id: </strong> ${trxnId} </p>
      </div>
      </div>
      <div class="section">
        <table class="table">
          <thead>
            <tr>
              <th>Description</th>
              <th>Qty</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
          ${products.map(item => {
            return (
              `<tr>
              <td>
                <p class="text-font-600">${item.name}</p>
                <p class="text-gray-600-sm">${item.description}</p>
              </td>
              <td>${item.quantity}</td>
              <td>${item.amount}</td>
            </tr>`
            )
          })}
          </tbody>
        </table>

        <div class="totals-section mt-6 flex justify-end">
          <div class="totals-summary w-64">
            <div class="totals-item flex justify-between py-2">
              <span>Subtotal:</span>
              <span>${subTotal}</span>
            </div>
            <div class="totals-item flex justify-between py-2">
              <span>Discount:</span>
              <span> - ${discount}</span>
            </div>
            <div class="totals-item flex justify-between py-2">
              <span>Demo Fee</span>
              <span>${demoFee}</span>
            </div>
            <div class="totals-item flex justify-between py-2">
              <span>Platform Fee</span>
              <span>${platformFee}</span>
            </div>
            <div class="totals-item flex justify-between py-2">
              <span>Tax:</span>
              <span>${tax}</span>
            </div>
            <div
              class="totals-item flex justify-between py-2 border-t-2 border-gray-300 font-bold"
            >
              <span style="color: red;">Total:</span>
              <span>Rs. ${total}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="footer">
        <div class="refund-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-7 text-orange-400"
            viewBox="0 0 24 24"
            fill="currentColor"
            style="width: 25px; height: 20px; color: #fb923c"
          >
            <path
              d="M11 9h2V7h-2v2zm0 4h2v6h-2v-6zm1-12C5.48 1 1 5.48 1 12s4.48 11 11 11 11-4.48 11-11S18.52 1 12 1zm0 20c-4.97 0-9-4.03-9-9s4.03-9 9-9 9 4.03 9 9-4.03 9-9 9z"
            />
          </svg>

          <h3>Refund & Cancellation Policy</h3>
        </div>

        <ul>
          <li>Refund requests must be made within 30 days of purchase</li>
          <li>
            Items must be returned in original packaging and unused condition
          </li>
          <li>Shipping costs are non-refundable</li>
          <li>Custom orders cannot be cancelled once production has begun</li>
          <li>Store credit is available for items returned after 30 days</li>
        </ul>
      </div>
    </div>
  </body>
</html>`;
  return htmlContent;
};
