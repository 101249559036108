export const capitalizeFirst = (str='') => {
  let strArr;
  if (typeof str === "string") strArr = str.trim().split(" ");
  else strArr = str;
  const newArr = strArr
    .filter((element) => element !== "")
    .map((char) => {
      return char.length ? char[0].toUpperCase().concat(char.substring(1)) : "";
    });
  if (typeof str === "string") return newArr.join(" ");
  return newArr.join(", ");
};

export const generateReceiptNo = (phone, userId, googleId) => {
  let receiptNo = `INVHC-${userId.slice(-5)}`;
  if(googleId){
    // using google Id:
    receiptNo += googleId.toString().slice(-3)
  }else{
    // using phone 
    receiptNo += phone.toString().substring(7)
  }
  return receiptNo
}