import { lazy, Suspense, useEffect } from "react";
import "./App.css";
import { ThemeProvider, createTheme } from "@mui/material/styles";
// import Home from "./containers/Home";
import { Box, CssBaseline } from "@mui/material";
import { Routes, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setLoading, setUser } from "./redux/user/userSlice";
import {
  QueryClient,
  QueryClientProvider
} from '@tanstack/react-query';

import ErrorPage from "./components/ErrorPage";

import { useFetchInterceptor } from "./hooks/useFetchInterceptor";
import PersistLogin from "./components/PersistLogin";
import Loader from "./containers/Loader";
import Terms from "./components/Terms";
import Privacy from "./components/Privacy";
import Apply from "./components/Apply";
import Contact from "./components/Contact";
import data from './mocks/data.json';
import RefundPolicy from "./components/RefundPolicy";
import PaymentSuccess from "./components/Payment/Success";
import PaymentFailure from "./components/Payment/Failure";
import InvoiceTemplate from "./components/Invoice";
import Profile from "./components/Profile";

const Register = lazy(() => import("./components/Register"));
const Home = lazy(() => import("./components/Home"));
const AddChef = lazy(() => import("./components/AddChef"));
const Login = lazy(() => import("./components/Login"));
const Dashboard = lazy(() => import("./components/Dashboard"));
const Navbar = lazy(() => import("./components/Navbar"));
const Footer = lazy(() => import("./components/Footer"));
const ChefList = lazy(() => import("./components/ChefList"));
const ViewCart = lazy(() => import('./components/ViewCart'));
const ViewWishList = lazy(() => import('./components/ViewWishList'));
const SingleChefPage = lazy(() => import("./components/ChefPage"));
const CheckOut = lazy(() => import("./components/Checkout"));
const Service = lazy(() => import("./components/Services"));

// Create a client
const queryClient = new QueryClient()

const LayoutWithNavbarFooter = () => (
  <>
    <Navbar />
       {/* Render child routes */}
    <Footer />
  </>
);

const AppRoutes = () => {
  return (
    <Routes>
      {/* public routes */}
      {/* <Route element={<PersistLogin />}>
        
      </Route> */}

      {/* protected routes */}
      <Route element={<PersistLogin />}>
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route element={<LayoutWithNavbarFooter />}>
          <Route path="/" element={<Home />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/addchef" element={<AddChef />} />
          <Route path="/viewcart" element={<ViewCart />} />
          <Route path="/wishlist" element={<ViewWishList />} />
          <Route path="/checkout" element={<CheckOut />} />
          <Route path="/preview-invoice" element={<InvoiceTemplate />} />
          <Route path="/ordersuccess/:orderId" element={<PaymentSuccess />} />
          <Route path="/paymentfailure" element={<PaymentFailure />} />
          <Route path="/chef">
            <Route path="list" element={<ChefList />} />
          </Route>
          <Route path="/chef/list/:id" element={<SingleChefPage />} />
          <Route path={`/${data.navlinks.terms}`} element={<Terms />} />
          <Route path={`/${data.navlinks.privacy}`} element={<Privacy />} />
          <Route path={`/${data.navlinks.refund}`} element={<RefundPolicy />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/apply" element={<Apply />} />
          <Route path="/services" element={<Service />} />
        </Route>
      </Route>

      {/* Fallback route */}
      <Route path="*" element={<ErrorPage />} />

    </Routes>
  )
}

function App() {
  const theme = createTheme();
  const dispatch = useDispatch();
  const fetchApiInterceptors = useFetchInterceptor();
  const { token } = useSelector((state) => state.auth);
  const {user} = useSelector(state => state.user);

  useEffect(() => {
    // get user details:
    const getUser = async () => {
      const userDetails = await fetchApiInterceptors(`/api/user`, token, {
        credentials: "include",
      });
      if (userDetails?.success) {
        dispatch(setLoading(false));
        dispatch(setUser({...user, ...userDetails.user, wishFlag:0}));
      }
    };
    if (token) getUser();
  }, [token, dispatch]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="App">
      <QueryClientProvider client={queryClient}>
        <Suspense fallback={<Loader />}>
          {/* <Navbar /> */}
          <Box className="main-container">
            <AppRoutes />
          </Box>
          {/* <Footer /> */}
        </Suspense>
        </QueryClientProvider>
      </div>
    </ThemeProvider>
  );
}

export default App;
