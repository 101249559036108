import {configureStore} from '@reduxjs/toolkit';
import exampleReducer from './example/exampleSlice';
import userReducer from './user/userSlice';
import chefReducer from './chef/chefSlice';
import authReducer from './auth/authSlice';
import checkoutReducer from './checkout/checkoutSlice';

export const store = configureStore({
    reducer: {
        example: exampleReducer,
        user: userReducer,
        chef: chefReducer,
        auth: authReducer,
        checkout: checkoutReducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat()
})