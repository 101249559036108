import React from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

const PaymentFailure = () => {
    const {paymentId} = useParams();
    const location = useLocation();
    const {pathname, state: {amount, orderId, currency} } = location;
    const {user} = useSelector((state) => state.user);
    const {user_id, name} = user;
  return (
    <div className="container failure-container mt-24">
      payment is failed for - {user_id} - {name} - {orderId} - {paymentId} - {amount} -
      {currency}
      Check with the customer care.
    </div>
  );
};

export default PaymentFailure;
