export const locationOptions = [
  "Noida",
  "Gurgoan",
  "Delhi Ncr",
  "Mumbai",
  "Bangalore",
  "other",
];

export const levelOptions = [
  "helper",
  "Commie 3",
  "Commie 2",
  "Commie 1",
  "CDP",
  "DCDP",
  "Head",
  "AllRounder",
];

export const cuisineOptions = [
  "Indian",
  "Chinese",
  "Continental",
  "Tandoor",
  "South Indian",
  "other",
];

export const statusCode = {
  addchef: "ERR CH201",
};

export const listItems = {
  name: "Name",
  cuisine: "Cuisine",
  experience: "Experience",
  level: "Level",
  location: "Location",
  workLocation: "Open Work Location",
};

export const filters = ["level", "location", "cuisine"];

export const payment = {
  helper: 1,
  chef: 2,
  platformFee: 2,
  demoFee: 0,
};

export const coupon = {
  HOMELYCHEFHURRAY: 4, // ON 1 workers => 4%
};

export const testimonial = [
  { rating: 4, name: "divyansh", content: "Testimonial rating" },
  { rating: 5, name: "abhishek", content: "Good rating" },
  { rating: 3, name: "divyansh1", content: "Better rating" },
  { rating: 4, name: "divyansh2", content: "Best rating" },
  { rating: 5, name: "divyansh3", content: "Good rating" },
  {
    rating: 4,
    name: "divyansh4",
    content:
      "Testimonial rating Testimonial rating Testimonial rating Testimonial rating",
  },
];

export const chefs = [
  {
    _id: "670e6ada353732a312e8b671",
    name: "testingtttt",
    // phone: "8111111111",
    location: "noida",
    workLocation: ["Noida"],
    cuisine: ["Chinese"],
    experience: 2,
    // curSalary: 22000,
    // expSalary:23000,
    level: "CDP",
    openToPartTime: true,
    // status: 1
    // added_by: '67043890fb1389b678a4c505',
    createdAt: "2024-10-15T13:15:06.778+00:00",
    updatedAt: "2024-10-15T13:24:59.496+00:00",
  },
];