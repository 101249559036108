import React from 'react';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import EmailIcon from '@mui/icons-material/Email';
import InfoIcon from '@mui/icons-material/Info';
import Image from '../../containers/Image';
import logo7 from '../../images/logo7.png';

function InvoiceTemplate() {
  // Sample data - in a real app this would come from props or API
  const invoice = {
    customerName: "John Doe",
    shopName: "Tech Haven",
    shopAddress: "123 Digital Street, Silicon Valley, CA 94025",
    shopPhone: "+1 (555) 123-4567",
    shopEmail: "contact@techhaven.com",
    invoiceId: "INV-2024-001",
    date: new Date().toLocaleDateString(),
    items: [
      { id: 1, name: "Laptop Pro X1", description: "15-inch, 32GB RAM, 1TB SSD", quantity: 1, price: 1499.99 },
      { id: 2, name: "Wireless Mouse", description: "Ergonomic Design, Battery Life: 6 months", quantity: 2, price: 49.99 },
      { id: 3, name: "USB-C Hub", description: "8-in-1 Multiport Adapter", quantity: 1, price: 79.99 },
    ],
    subtotal: 1679.96,
    tax: 0,
    discount: 50,
    total: 1797.95
  };

  return (
    <div className="min-h-screen bg-gray-100 py-8 px-4">
      <div className="max-w-4xl mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
        {/* Header */}
        <div className="bg-blue-600 px-6 py-3">
          <div className="flex justify-between items-center">
            <div className="text-white">
              <div className="flex items-center gap-2">
                <Image src={logo7} alt={'HomelyChef'} classNames={'w-8 h-11'}/>
                <h1 className="text-2xl font-bold">HomelyChef</h1>
              </div>
            </div>
            <div className="text-white text-right">
              <h2 className="text-3xl font-bold">INVOICE</h2>
              <p className="mt-2">Invoice No: {invoice.invoiceId}</p>
              <p>Date: {invoice.date}</p>
            </div>
          </div>
        </div>

        {/* Customer Info */}
        <div className="px-8 py-6 border-b text-left">
          <h3 className="text-lg text-gray-600 font-semibold mb-2">Bill To:</h3>
          <p className="text-sm font-bold">{invoice.customerName}</p>
          <p className="text-sm font-bold">{invoice.shopName}</p>
          <p className="text-sm font-bold">{invoice.shopAddress}</p>
          <div className='flex gap-2 items-center'>
            <PhoneInTalkIcon className='w-4 fill-orange-400'/>
            <p className="text-sm font-bold">{invoice.shopPhone}</p>
          </div>
        </div>

        {/* Items */}
        <div className="px-8 py-6">
          <table className="w-full">
            <thead className='bg-gray-100'>
              <tr className="border border-gray-400">
                <th className="text-left py-3 px-2 border border-l-0 border-t-0 border-b-0 border-gray-400">Description</th>
                <th className="text-center py-3 border border-l-0 border-t-0 border-b-0 border-gray-400">Qty</th>
                <th className="text-center py-3 border border-l-0 border-t-0 border-b-0 border-gray-400">Price</th>
                <th className="text-center py-3">Amount</th>
              </tr>
            </thead>
            <tbody>
              {invoice.items.map((item) => (
                <tr key={item.id} className="border border-t-0 border-gray-400">
                  <td className="py-4 px-2 w-1/2 text-left border border-l-0 border-t-0 border-b-0 border-gray-400">
                    {/* put category of worker (chef or helper) */}
                    <p className="font-semibold">{item.name}</p>
                    {/* put cuisine of worker (chinese or indian etc.) */}
                    <p className="text-gray-600 text-sm">{item.description}</p>
                  </td>
                  <td className="text-center py-4 border border-l-0 border-t-0 border-b-0 border-gray-400">{item.quantity}</td>
                  <td className="text-center py-4 border border-l-0 border-t-0 border-b-0 border-gray-400">${item.price.toFixed(2)}</td>
                  <td className="text-center py-4">${(item.quantity * item.price).toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Totals */}
          <div className="mt-6 flex justify-end">
            <div className="w-64">
              <div className="flex justify-between py-2">
                <span>Subtotal:</span>
                <span>{invoice.subtotal.toFixed(2)}</span>
              </div>
              <div className="flex justify-between py-2">
                <span>Tax:</span>
                <span>{invoice.tax.toFixed(2)}</span>
              </div>
              <div className="flex justify-between py-2">
                <span>Discount:</span>
                <span>-{invoice.discount.toFixed(2)}</span>
              </div>
              <div className="flex justify-between py-2 border-t-2 border-gray-300 font-bold">
                <span>Total:</span>
                <span>{invoice.total.toFixed(2)}</span>
              </div>
            </div>
          </div>
        </div>

        {/* Terms and Conditions */}
        <div className="px-8 py-6 bg-gray-50">
          <div className="flex items-center gap-2 mb-0">
            <InfoIcon className="w-7 text-orange-400" />
            <h3 className="font-semibold text-sm underline">Refund & Cancellation Policy</h3>
          </div>
          <ul className="list-disc list-inside text-left space-y-2 mt-0 text-gray-600">
            {/* put some more policies */}
            <li className='text-sm'>Refund requests must be made within 30 days of purchase</li>
            <li className='text-sm'>Items must be returned in original packaging and unused condition</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default InvoiceTemplate;