import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setLoading } from "../../redux/user/userSlice";
import Loader from "../../containers/Loader";

const Profile = () => {
  const {user, loading} = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!user?.user_id) {
      dispatch(setLoading(true));
      return navigate("/login");
    } else if (user?.role != "0") {
      return navigate("/error", {
        state: { message: "Not authorized to view this page!" },
      });
    }
  }, []);

  if(loading) return <Loader />

  return <div className="container dashboard-container mt-24">Dashboard user - {user?.name}</div>;
};

export default Profile ;
